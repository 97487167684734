import { ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default function useCars() {
    const carsForRent = ref([]);
    const carsForSale = ref([]);
    const brandsList = ref([]);
    const destinations = ref([]);
    const carsDetail = ref({});
    const carPrice = ref({});
    const loading = ref(false);
    const name_ie = ref('');
    const isError = ref(false);
    const errors = ref(null);
    const slug = ref('');
    const marque = ref(null);
    const total = ref(0)
    const totalPages = ref(0)
    const totalRent = ref(null)
    const search = ref('')
    const filterByDate = ref('');
    const fuel = ref('');
    const year = ref('');
    const name = ref('');
    const perPage = ref(9)
    const cars = ref([])
    const currentPage = ref(1)
    const destinationSlug = ref("PR49RBIvN0bzOWU1jBdXWYjzsiwVbsXWHY0JdTW0");

    const store = useStore()

    const getCarsForRent = async () => {
        try {
            loading.value = true;
            let response = await axios.get('/cars/?name_ie='+ name.value +'&color=&year='+ year.value +'&type_price__slug=' + destinationSlug.value + '&seats=&fuel=' + fuel.value + '&page=' + currentPage.value);
            if (response.status === 200) {
                loading.value = false;
                carsForRent.value = response.data
                totalRent.value = response.data.count
                totalPages.value = Math.ceil(response.data.count / 9)
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    const getCarsForSale = async () => {
        try {
            loading.value = true;
            // local 
            // let response = await axios.get('/cars/?name_ie='+ name.value +'&color=&year='+ year.value +'&price__type_price__slug=0fxo3OxiCgHZ68P64yUfjWucizHcVzEKLkDNlXhF&seats=&fuel=' + fuel.value + '&year_lt=&year_gt=');
            // production 
            let response = await axios.get('/cars/?name_ie='+ name.value +'&color=&year='+ year.value +'&price__type_price__slug=9nfA3wWzjqCezSzISKEQshsWBBbaHxMA7szWjH93&seats=&fuel=' + fuel.value + '&year_lt=&year_gt=');
            if (response.status === 200) {
                loading.value = false;
                carsForSale.value = response.data
                total.value = response.data.count
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    const onCarsDetail = async (slug) => {
        try {
            loading.value = true;
            let response = await axios.get('/cars/' + slug);
            if (response.status === 200) {
                console.log(response.data)
                loading.value = false;
                carsDetail.value = response.data
                carPrice.value = response.data
                marque.value = response.data.brand.name
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    const getCarsBrand = async () => {
        try {
            loading.value = true;
            let response = await axios.get('/cars/brands');
            if (response.status === 200) {
                loading.value = false;
                brandsList.value = response.data
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    const getCarsByBrand = async (slug) => {
        try {
            loading.value = true;
            let response = await axios.get('/cars/brands/' + slug);
            if (response.status === 200) {
                loading.value = false;
                cars.value = response.data
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    const getDestination = async () => {
        try {
            loading.value = true;
            let response = await axios.get('/cars/price-types/');
            if (response.status === 200) {
                loading.value = false;
                destinations.value = response.data.results
                localStorage.setItem('destinations', JSON.stringify(response.data.results))
                store.commit('SET_DESTINATIONS', destinations.value);
            }
        } catch (error) {
            loading.value = false;
            isError.value = true;
            errors.value = "Connexion impossible, veuillez réessayez plus tard."
        }
    }

    return {
        brandsList,
        carsDetail,
        carsForRent,
        carsForSale,
        errors,
        filterByDate,
        fuel,
        isError,
        loading,
        marque,
        name,
        search,
        slug,
        total,
        totalRent,
        year,
        destinations,
        destinationSlug,
        carPrice,
        cars,
        currentPage,
        totalPages,
        perPage,
        getDestination,
        getCarsBrand,
        getCarsByBrand,
        getCarsForRent,
        getCarsForSale,
        onCarsDetail
    }
}