const routes = [
    {
        path: '/',
        component: () => import('../components/layouts/DefaultLayout.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('../views/HomeView.vue')
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('../views/AboutView.vue')
            },
            {
                path: '/politique-de-confidentialite',
                name: 'politique',
                component: () => import('../views/Confidentiality.vue')
            },
            {
                path: '/comment-ca-marche',
                name: 'howitworks',
                component: () => import('../views/HowItWorks.vue')
            }
        ]
    }
];

export default routes;