<template>
  <router-view />
</template>
<script>
import useCars from '@/services/cars';
import { onMounted } from 'vue';
export default {
  name: 'App',

  setup() {
    const { loading, destinations, getDestination } = useCars();

    onMounted(async () => {
      await getDestination()
    })

    return {
      loading,
      destinations
    }
  }
}
</script>

<style>

</style>
