import { createStore } from "vuex";

export default createStore({
  state: {
    openOrder: false,
    nbDays: 1,
    destinations: [],
    prices: [],
    location: "",
    startDate: new Date(),
    endsDate: new Date(),
    showAlert: false
  },
  getters: {
    getLocation(state) {
      return state.location
    }, 
    getStartD(state) {
      return state.startDate
    },
    getEndsD(state) {
      return state.endsDate
    },
    getDestinations(state) {
      return state.destinations
    }
  },
  mutations: {
    SET_OPEN_ORDER(state, payload) {
      state.openOrder = payload;
    },
    SET_NB_DAYS(state, payload) {
      state.nbDays = payload;
    },
    SET_START_DATE(state, payload) {
      state.startDate = payload;
    },
    SET_SHOW_ALERT(state, payload) {
      state.showAlert = payload;
    },

    SET_ENDS_DATE(state, payload) {
      state.endsDate = payload;
    },
    SET_LOCATION(state, payload) {
      state.location = payload;
    },
    SET_DESTINATIONS(state, payload) {
      state.destinations = payload;
    },
    SET_PRICES(state, payload) {
      state.prices = payload;
    },
  },
  actions: {},
  modules: {},
});
