const routes = [
    {
        path: '/detail',
        component: () => import('../components/layouts/DefaultLayout.vue'),
        children: [
            {
                path: '/vehicules/:slug',
                name: 'detail',
                component: () => import('../views/DetailView.vue')
            },
            {
                path: '/vehicules-location',
                name: 'location',
                component: () => import('../views/LocationView.vue')
            },
            {
                path: '/vehicules-vente',
                name: 'vendre',
                component: () => import('../views/VenteView.vue')
            },
            {
                path: '/contact',
                name: 'contact',
                component: () => import('../views/ContactView.vue')
            }           
        ]
    }
];

export default routes;