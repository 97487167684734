import { createRouter, createWebHistory } from "vue-router";
import indexRoutes from './indexRoutes';
import otherRoutes from './otherRoutes';

const allRoutes = [];
const routes = allRoutes.concat(indexRoutes, otherRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) { return { top: 0 }}, 
  routes,
});

export default router;
