import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from  "axios"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_DEV_URL;

createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueDatePicker)
    .mount("#app");
